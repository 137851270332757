// src/components/Home.js
import React from 'react';
import './Home.css'; // Import the new CSS file

const Home = () => {
  return (
    <div className="home-container">
      <h1 className="home-title">Welcome to the My Corp Home Page!</h1>
      <p className="home-description">
        This is the landing page of your application. Navigate using the links above to explore different sections. Enjoy a seamless experience with our simple and clean design.
      </p>
      <div className="home-cards">
        <div className="home-card">
          <h2>Feature 1</h2>
          <p>Explore the main features of this web application.</p>
        </div>
        <div className="home-card">
          <h2>Feature 2</h2>
          <p>Interact with various sections and learn more.</p>
        </div>
        <div className="home-card">
          <h2>Feature 3</h2>
          <p>Stay tuned for more updates and improvements!</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
