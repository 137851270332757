// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Home from './components/Home';
import Comments from './components/Comments';
import './App.css'; // Import the new CSS file

const App = () => {
  return (
    <Router>
      <nav className="navbar">
        <div className="navbar-container">
          <NavLink to="/" className="nav-link" activeClassName="active-link" exact>
            Home
          </NavLink>
          <NavLink to="/comments" className="nav-link" activeClassName="active-link">
            Comments
          </NavLink>
        </div>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/comments" element={<Comments />} />
      </Routes>
    </Router>
  );
};

export default App;
