// src/components/Comments.js
import React, { useState, useEffect } from 'react';
import './Comments.css';

const WORKER_API_URL = 'https://cloudflare-worker-comments.lewis-ede.workers.dev'; // Replace with your Worker URL

const Comments = () => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');

  // Fetch comments from the Cloudflare Worker
  useEffect(() => {
    fetch(WORKER_API_URL)
      .then((response) => response.json())
      .then((data) => setComments(data))
      .catch((error) => console.error('Error fetching comments:', error));
  }, []);

  // Handle adding a new comment
  const handleAddComment = (e) => {
    e.preventDefault();
    if (commentText.trim() === '') return;

    fetch(WORKER_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text: commentText }),
    })
      .then((response) => {
        if (response.ok) {
          setComments([{ text: commentText, created_at: new Date().toISOString(), id: Date.now() }, ...comments]);
          setCommentText('');
        }
      })
      .catch((error) => console.error('Error adding comment:', error));
  };

  // Handle deleting a comment
  const handleDeleteComment = (id) => {
    fetch(`${WORKER_API_URL}/${id}`, {
      method: 'DELETE',
    })
      .then((response) => {
        if (response.ok) {
          setComments(comments.filter((comment) => comment.id !== id));
        } else {
          console.error('Failed to delete comment');
        }
      })
      .catch((error) => console.error('Error deleting comment:', error));
  };

  return (
    <div className="comments-container">
      <h1 className="page-title">Comments Page</h1>
      <form className="comment-form" onSubmit={handleAddComment}>
        <input
          type="text"
          className="comment-input"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="Add a comment..."
        />
        <button type="submit" className="add-button">
          Add Comment
        </button>
      </form>
      <div className="comments-list">
        <h2 className="comments-title">Comments:</h2>
        <ul>
          {comments.map((comment) => (
            <li key={comment.id} className="comment-item">
              {comment.text}
              <button className="delete-button" onClick={() => handleDeleteComment(comment.id)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Comments